import { SharedModule } from '$/app/shared/shared.module';
import { Logger } from '$shared/logger';
import { REGEX } from '$shared/regex';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  inject
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  standalone: true,
  selector: 'alc-password-input',
  templateUrl: './password-input.component.html',
  imports: [SharedModule],
  styles: [
    `
      mat-icon {
        color: var(--ion-color-medium);
      }
    `
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true })
    }
  ]
})
export class AlcPasswordInputComponent implements OnDestroy {
  private readonly parentContainer = inject(ControlContainer);

  @Input() controlName = 'password';
  @Input() id: string;
  @Input() label = 'Password';
  @Input() validateCharacters = true;
  @Input() required = true;
  @Input() mustMatchField: string;
  @Input() class = '';
  @Input() inputClass = '';
  @Input() hidePassword = true;
  @Output() keydownEnter = new EventEmitter<Event>();

  private validators: ValidatorFn[] | undefined;

  protected get parentFormGroup() {
    return this.parentContainer.control as UntypedFormGroup;
  }

  protected get control() {
    const control = this.parentFormGroup.get(this.controlName) as FormControl;

    Logger.assert(
      control,
      `Control not found - did you forget to add ${this.controlName} to your form definition?`
    );

    this.ensureValidators(control);

    return control;
  }

  ngOnDestroy() {
    if (this.validators) {
      this.control.removeValidators(this.validators);
      this.validators = undefined;
    }
  }

  private ensureValidators(control: FormControl) {
    if (!this.validators) {
      this.validators = [
        this.validateCharacters && Validators.pattern(REGEX.PASSWORD),
        this.required && Validators.required,
        this.mustMatchField &&
          RxwebValidators.compare({
            fieldName: this.mustMatchField,
            message: 'Passwords do not match'
          })
      ].filter(Boolean);

      control.addValidators(this.validators);
    }
  }

  protected get hasUppercase() {
    return REGEX.CONTAINS_UPPERCASE.test(this.control.value);
  }

  protected get hasLowercase() {
    return REGEX.CONTAINS_LOWERCASE.test(this.control.value);
  }

  protected get hasNumber() {
    return REGEX.CONTAINS_NUMBER.test(this.control.value);
  }

  protected get hasLength() {
    return this.control.value.length >= 8;
  }
}
