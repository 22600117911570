<mat-form-field [class]="class">
  <mat-label [class]="inputClass">{{ label }}</mat-label>
  <input
    matInput
    [attr.id]="id ?? controlName"
    [attr.name]="id ?? controlName"
    [attr.autocomplete]="id ?? controlName"
    [attr.data-testid]="id ?? controlName"
    [class]="inputClass"
    [type]="hidePassword ? 'password' : 'text'"
    [formControlName]="controlName"
    (keydown.enter)="keydownEnter.emit($event)"
  />

  @if (validateCharacters) {
    <mat-hint>
      <span class="-mt-3 flex flex-wrap gap-1">
        <ng-template
          *ngTemplateOutlet="feedback; context: { errorColor: 'text-primary' }"
        />
      </span>
    </mat-hint>
  }

  <mat-error>
    <span [alcError]="control"></span>

    @if (validateCharacters && control.hasError('pattern')) {
      <span class="-mt-5 flex flex-wrap gap-1 leading-tight">
        <ng-template
          *ngTemplateOutlet="feedback; context: { errorColor: 'text-red' }"
        />
      </span>
    }
  </mat-error>

  <button
    matSuffix
    mat-icon-button
    class="visibility-btn"
    type="button"
    (click)="hidePassword = !hidePassword"
  >
    <mat-icon>
      {{ hidePassword ? 'visibility_off' : 'visibility' }}
    </mat-icon>
  </button>
</mat-form-field>

<ng-template #feedback let-errorColor="errorColor">
  <span
    [ngClass]="hasLength ? 'text-success' : errorColor"
    class="flex items-center"
  >
    8 characters
    @if (hasLength) {
      <ion-icon name="checkmark-circle-outline" class="ml-1" />
    }
  </span>
  <span
    [ngClass]="hasUppercase ? 'text-success' : errorColor"
    class="flex items-center"
  >
    Uppercase
    @if (hasUppercase) {
      <ion-icon name="checkmark-circle-outline" class="ml-1" />
    }
  </span>
  <span
    [ngClass]="hasLowercase ? 'text-success' : errorColor"
    class="flex items-center"
  >
    Lowercase
    @if (hasLowercase) {
      <ion-icon name="checkmark-circle-outline" class="ml-1" />
    }
  </span>
  <span
    [ngClass]="hasNumber ? 'text-success' : errorColor"
    class="flex items-center"
  >
    Number
    @if (hasNumber) {
      <ion-icon name="checkmark-circle-outline" class="ml-1" />
    }
  </span>
</ng-template>
